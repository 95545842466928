<template>
    <div class="quickCashier">
        <!-- 快速收银 -->
        <div class="top">
            <div class="left">
                
                <div class="member" v-if="currentMember && currentMember.user_id">
                    <img :src="currentMember.avatar_url" v-if="currentMember.avatar_url != null" alt="">
                    <img src="../../assets/default-avatar.png" v-else alt="">
                    <div class="info">
                        <div class="photo">{{currentMember.mobile}}</div>
                        <div>余额:￥{{currentMember.balance}}</div>
                    </div>
                    <div class="close">
                        <a-icon type="close" @click="deleteMember"/>
                    </div>
                </div>
                <div class="inputBox">
                    <searchInput 
                    @search="handleSearch"
                    @change="handleChange" 
                    @clearData="searchData = []" 
                    placeholder="商品名称/编号/条码"
                    :searchData="searchData"
                    :orderList="[]"
                    :clearVal="clearVal"
                    :haveBlur="haveBlur"
                    ></searchInput>
                </div>
                <div class="cartList" :style="currentMember && currentMember.user_id ? 'height:56.5vh;' : 'height:63.9vh'">
                    <div :class="activeGoods == i ? 'goods_item hoverbgc checkedbgc' : 'goods_item hoverbgc'" @click="checkCartGoods(i)" v-for="(item,i) in cartList" :key="i">
                        <div class="name">{{item.goods_name}}<span v-if="item.guige">（{{item.guige}}）</span></div>
                        <div class="zuofa">
                            <span>{{item.zuofa}}</span>
                            <span>{{item.zuofa && item.kouwei ? '、' : ''}}</span>
                            <span>{{item.kouwei}}</span>
                            <span>{{item.kouwei && item.jikou ? '、' : ''}}</span>
                            <span>{{item.jikou}}</span>
                        </div>
                        <div class="price_num">
                            <div class="price">
                                <!-- {{(item.goods_price * item.total_num).toFixed(2)}} -->
                                <span> {{item.is_giving !=2?(item.goods_price * item.total_num).toFixed(2):0.00}}</span>
                                <span v-if="item.is_giving ==2" style="">（赠）</span>
                            </div>

                            <div class="num">
                                <span class="iconfont icon-jianqu" @click="changeNum(item,'jian',i)"></span>
                                <!-- <input v-model="item.num" class="inp" type="text" @input="numChange(item)"> -->
                                <span class="inp">{{item.total_num}}</span>
                                <span class="iconfont icon-jia" @click="changeNum(item,'jia',i)"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn_box">
                    <!-- <div class="jiezhangLoding" v-if="showLoding">
                        <a-icon type="loading" />
                        请求中...
                    </div> -->
                    <div class="jiezhangBtn" style="cursor:auto">
                        <!-- <a-icon type="plus" /> 结算-->
                        ￥{{orderPrice || total_money}}
                    </div>
                    <div  :class="currentMember && currentMember.user_id ? 'jiezhangBtn huiyuan_a' : 'huiyuan'" @click="showMember = true">会员<b>(F1)</b></div>
                </div>
                
            </div>
            <!-- <div class="center"> -->
                <!-- <div :class="currentMember && currentMember.user_id ? 'bgColor' : ''" @click="showMember = true">会员<b>(F1)</b></div> -->
                <!-- <div @click="openLinshi(1)">临时商品</div> -->
                <!-- <div @click="openLinshi(2)">临时称重</div> -->
                <!-- <div @click="changeNumHandle('num')">数量<b>(F2)</b></div> -->
                <!-- <div @click="changeNumHandle('price')">改价<b>(F3)</b></div> -->
                <!-- <div>优惠<b>(F4)</b></div> -->
                <!-- <div @click="deleteGoods">删除<b>(F5)</b></div> -->
                <!-- <div @click="pendingOrderHandle">{{cartList && cartList.length || (!pendingOrder || !pendingOrder.length) ? '挂单' : '取单'}}<b>(F6)</b></div> -->
                <!-- <div @click="empty">清空</div> -->
                <!-- <div>钱箱</div> -->
                <!-- <div>重打上单</div> -->
            <!-- </div> -->
            <div class="right">
                <div class="classify">
                    <div :class="'all ' + (category_id == 'all' ? 'bgColor' : '')" @click="goodsRequest('all')">全部</div>
                    <div class="scroll_box">
                        <div class="scroll_box2" ref="scroll_box2" :style="'transform:translateX(-' + srrollWidth + 'vw)'">
                            <div v-for="(item,index) in cateList" :key="index" :class="category_id == item.category_id ? 'bgColor all' : 'all'" @click="goodsRequest(item.category_id)">{{item.name}}</div>
                        </div>
                    </div>
                    <div class="xiang" style="width:4vw;" @click="showAllCate = true">
                        <span class="iconfont icon-xiangyou" style=""></span>
                    </div>
                    <div class="allBox" v-if="showAllCate">
                        <div :class="'all ' + (category_id == 'all' ? 'bgColor' : '')" @click="goodsRequest('all')">全部</div>
                        <div v-for="(item,index) in cateList" :key="index" :class="category_id == item.category_id ? 'bgColor all' : 'all'" @click="goodsRequest(item.category_id)">{{item.name}}</div>
                        <div class="all" style="width:4vw;" @click="showAllCate = false">
                            <span class="iconfont icon-xiangshang" style=""></span>
                        </div>
                    </div>
                </div>
                <div class="goods_list">
                    <div class="goods_item" :style="'width:' + (73.5 - rowNum ) / rowNum + 'vw;height:' + (model_type==2 ? 82 : 140) / rowNum + 'vh;font-size:' + (model_type==2 ? 16 : 14) / rowNum + 'vh'" v-for="(item,i) in goodsList" :key="i" @click="checkGoods(item)">
                        <div v-if="model_type !=2">
                            <div class="img_box">
                                <img :src="item.preview_url" v-if="item.preview_url" alt="">
                                <img src="../../assets/defaultImg.png" v-else alt="">
                            </div>
                            <div class="name">{{item.goods_name}}</div>
                            <div class="price">￥{{item.goods_price}}</div>
                        </div>
                        <div class="bigFont" v-if="model_type==2">
                            <div >
                                <div class="names">{{item.goods_name}}</div>
                                <div class="price">￥{{item.goods_price}}</div>
                            </div>
                        </div>
                        <div v-if="item.is_weighing == 2" class="weighing">称重</div>
                        <div v-if="item.is_soldout===2" class="shouqing">
                            <div class="bg"></div>
                            <div class="text">售罄</div>
                        </div>
                        <!-- <div v-if="item.limited_stock_total !=0.00" class="weighing">限量沽清</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="left_btn">
                <div @click="changeNumHandle('num')">数量<b>(F2)</b></div>
                <div @click="changeNumHandle('price')" v-if="permission.indexOf('/clerk/goods/changePrice') > -1">改价<b>(F3)</b></div>
                <div @click="deleteGoods">删除<b>(F5)</b></div>
                <div @click="empty">清空</div>
                <div @click="pendingOrderHandle">{{cartList && cartList.length || (!pendingOrder || !pendingOrder.length) ? '挂单' : '取单'}}<b>(F6)</b></div>
                <div @click="givingButton" v-if="cartList.length>0 && cartList[this.activeGoods].is_giving !=2">赠送</div>
                <div @click="deleGivingButton" v-if="cartList.length>0 && cartList[this.activeGoods].is_giving ==2">取消赠送</div>
                <!-- <div>打开钱箱</div> -->
            </div>
            <div class="right_btn">
                <div class="jiezhangBtn" @click="settlement">结账<br>(F11)</div>
                <div class="jiezhangBtn xianjin_btn" @click="settlement(1)">现金<br>(F10)</div>
                <div class="jiezhangBtn" @click="settlement(3)">扫码结算<br>(F9)</div>
            </div>
        </div>
        <div class="searchGoods" v-if="!showSearchGoods">
            <div class="searchInp">
                <a-input class="inp" @pressEnter="searchGoodsHandle" placeholder="请输入商品名称" v-model="goods_name"/>
                <span @click="searchGoodsHandle">查询</span>
            </div>
            <div class="searchGoodsList" v-if="searchGoodsList && searchGoodsList.length">
                <div class="item hoverbgc" v-for="(item,i) in searchGoodsList" :key="i" @click="checkGoods(item)">
                    {{item.goods_name}}
                </div>
            </div>
            <div class="searchGoodsList noGoods"  v-if="searchGoodsList.length == 0 && showNoGoods">没有搜到该商品</div>
        </div>
        <pending :showModal="showPend" @closeHandle="showPend = false"></pending>
        <changePrice :showModal="showChangePrice" @closeHandle="showChangePrice = false" :activeGoods="activeGoods"></changePrice>
        <changeNum :showModal="showChangeNum" @closeHandle="showChangeNum = false" :activeGoods="activeGoods"></changeNum>
        <member :showDrawer="showMember" @closeHandle="showMember = false" @okHandle="memberOkHandle" :isSettle="isSettle" :fromHuiyuanPay="fromHuiyuanPay"></member>
        <settleHandle :showDrawer="showSettleHandle" @closeHandle="showSettleHandle = false" @handleOrder="handleOrder"></settleHandle>
        <xianjinPay :showDrawer="showXianjinPay" @closeHandle="showXianjinPay = false" @FinishOrder="FinishOrder('showXianjinPay')" @goSettlePage="goSettlePage"></xianjinPay>
        <weixinPay :showDrawer="showWeixinPay" @closeHandle="showWeixinPay = false" @FinishOrder="FinishOrder('showWeixinPay')" @goSettlePage="goSettlePage"></weixinPay>
        <zhifubao :showDrawer="showZhifubaoPay" @closeHandle="showZhifubaoPay = false" @FinishOrder="FinishOrder('showZhifubaoPay')" @goSettlePage="goSettlePage"></zhifubao>
        <saomaPay :showDrawer="showSaomaPay" @closeHandle="showSaomaPay = false"  @FinishOrder="FinishOrder('showSaomaPay')" @goSettlePage="goSettlePage"></saomaPay>
        <huiyuanPay :showDrawer="showHuiyuanPay" @closeHandle="showHuiyuanPay = false"  @FinishOrder="FinishOrder('showHuiyuanPay')" @goSettlePage="goSettlePage"></huiyuanPay>
        <zhekouPay :showDrawer="showZhekou" @closeHandle="showZhekou = false"  @goSettlePage="goSettlePage"></zhekouPay>
        <jianmianPay :showDrawer="showJianmian" @closeHandle="showJianmian = false"  @goSettlePage="goSettlePage"></jianmianPay>
        <recharge :showModal="showRecharge" @closeHandle="showRecharge = false"></recharge>
        <weighing :showModal="showWeight" @closeHandle="showWeight = false" @openGuige="showWeight = false;showGuige = true"></weighing>
        <practice :showModal="showGuige" @closeHandle="showGuige = false"></practice>
        <scanTips :showModal="showScanTip" @closeScanTip="showScanTip=false" @closeScanOk="showScanTip=false,showAddGoods=true"></scanTips>
        <addGoods :showModal="showAddGoods" @closeAddGoods="showAddGoods=false" :barcode="barcode" @addShops="handleSearchs" @update="goodsRequest(category_id)"></addGoods>
        <temporary :showModal="showLinshi" @cancel="showLinshi = false"></temporary>
        <temporaryCopy :showModal="showLinshiCZ" @cancel="showLinshiCZ = false"></temporaryCopy>
    </div>
</template>
<script>
import { mapState , mapMutations } from "vuex";
import {categoryList,goodsList} from '@/request/business.js'
import pending from './modules/pending.vue'                          //取单
import changeNum from './modules/changeNum.vue'                      //改数量
import changePrice from './modules/changePrice.vue'                  //改价
import member from './modules/member.vue'                            //会员查询 会员注册 会员详情
import settleHandle from './modules/settleHandle.vue'                //结算操作
// import settle from './modules/settle.vue'                            //
import xianjinPay from './modules/pay_xianjin.vue'                   //现金结算
import weixinPay from './modules/pay_xianjin_weixin.vue'
import zhifubao from './modules/pay_xianjin_zhifubao.vue'
import saomaPay from './modules/pay_saoma.vue'                       //扫码结算
import huiyuanPay from './modules/pay_huiyuan.vue'                   //会员结算
import zhekouPay from './modules/pay_zhekou.vue'                     //自定义折扣
import jianmianPay from './modules/pay_jianmian.vue'                 //减免
import recharge from './modules/recharge.vue'                        //会员充值
import weighing from './modules/weighing.vue'                        //称重
import practice from './modules/practice.vue'                        //做法 口味 忌口
import scanTips from './modules/scanTips.vue'                        //未扫描出来商品提示
import addGoods from './modules/addGoods.vue'                        //添加商品
import temporary from './modules/temporary.vue'                      //临时菜品
import temporaryCopy from './modules/temporaryCopy.vue'              //临时称重
import Cookies from "js-cookie";
import searchInput from '@/components/searchInput/searchInput.vue'
import { Founding , orderCreate , orderUser , OrderDetail} from "@/request/fast_food_settle";
import {getScreenSetting} from '@/request/business.js'
import { billList } from "@/request/bill.js";  
import { orderpay , orderpaystatus } from "@/request/fast_food_settle";
import "@/assets/font/font.css"
export default {
    name:'quickCashier',
    data(){
        return{
            searchData:[],
            searchInputValue:'',
            clearVal:0,
            cateList:[],
            category_id:'all',
            goodsList:[],
            activeGoods:0,
            showSearchGoods:true,
            searchGoodsList:[],
            goods_name:'',
            showNoGoods:false,
            srrollWidth: 0,
            rightBtn:0,
            leftBtn:0,
            showPend:false,
            showChangePrice:false,
            showChangeNum:false,
            changeGoods:{},
            showMember: false,
            showSettleHandle: false,
            showXianjinPay:false,
            showWeixinPay:false,
            showZhifubaoPay:false,
            showSaomaPay:false,
            showHuiyuanPay:false,
            showZhekou:false,
            showJianmian:false,
            showRecharge:false,
            showWeight:false,
            showGuige:false,
            isSettle:false,  // 是否是结算页跳更换会员
            fromHuiyuanPay:false, //是否是未绑定会员点击结算页余额结算跳到的会员页
            have_order:false,
            showLoding:false,
            orderPrice:'',
            showScanTip:false,
            showAddGoods:false,
            barcode:"",//商品条形编码
	        showLinshi:false,  // 临时商品
	        showLinshiCZ:false,  // 临时称重 商品
	        model_type:"",//1正常模式 2大字模式
            rowNum: 7 ,
            pay_code:'',
            showAllCate:false  // 显示所有分类
        }
    },
    computed: {
        ...mapState({
            cartList:state=>state.fast_food.cartList,
            pendingOrder:state=>state.fast_food.pendingOrder,
            currentMember:state=>state.fast_food.currentMember,
            orderInfo:state=>state.fast_food.orderInfo,
            permission:state=>state.permission,
        }),
        total_money(){
            let money = 0
            this.cartList.forEach(item => {
                 if(item.is_giving !=2){
                    money += item.goods_price * 1 * item.total_num
                }
                // money += item.goods_price * 1 * item.total_num
            });
            money = money.toFixed(2)
            return money
        },
        have_payLog(){
            let have = false
            // console.log(JSON.stringify(this.orderInfo))
            if(this.orderInfo && this.orderInfo.paylog && this.orderInfo.paylog.length > 0 && this.orderInfo.nopay_price !='0.00'){
                have = true
            }
            if(this.orderInfo.order_type == 2){
                have = true
            }
            return have
        },
        haveBlur(){
            let have = this.showSearchGoods && !this.showChangePrice 
            && !this.showChangeNum && !this.showMember && !this.showSettleHandle
            && !this.showXianjinPay && !this.showSaomaPay && !this.showHuiyuanPay && !this.showZhekou && !this.showWeixinPay
             && !this.showZhifubaoPay && !this.showGuige
            && !this.showJianmian && !this.showWeight && !this.showAddGoods && !this.showLinshi && !this.showLinshiCZ
            return have 
        }
    },
    watch:{
        have_order(val){
            console.log(val);
        },
        orderInfo:{
            handler(val){
                this.orderPrice = val.nopay_price
            },
            deep:true
        },
        cartList:{
            handler(val){
                this.orderPrice = ''
            },
            deep:true
        },
        total_money(val){
            const screenObject= JSON.parse(localStorage.getItem('screen'))?JSON.parse(localStorage.getItem('screen')):{use:2,com:undefined,potter:undefined,}
            const com=screenObject.com;
            const potter=parseFloat(screenObject.potter);
            if(val && screenObject.use==1){
                bound.vScreen(com,potter,val,false)
            }else{
                bound.vScreen(com,potter,"0.00",false)
            }  
        },
        orderPrice(val){
            const screenObject= JSON.parse(localStorage.getItem('screen'))?JSON.parse(localStorage.getItem('screen')):{use:2,com:undefined,potter:undefined,}
            const com=screenObject.com;
            const potter=parseFloat(screenObject.potter);
            if(val && screenObject.use==1){
                bound.vScreen(com,potter,val,false)
            }else{
                bound.vScreen(com,potter,"0.00",false)
            }
        }

    },
    components:{ 
        pending , 
        changeNum , 
        changePrice , 
        member , 
        settleHandle , 
        xianjinPay , 
        weixinPay ,
        zhifubao ,
        saomaPay , 
        huiyuanPay , 
        zhekouPay , 
        jianmianPay , 
        recharge , 
        weighing ,
        practice ,
        searchInput,
        scanTips,
        addGoods,
        temporary,
        temporaryCopy
    },
    mounted(){
        this.getDetail()
        this.tabRequest();
        this.goodsRequest('all');
        this.getUndoneOrder()
        if(this.have_payLog){
            setTimeout(() => {
                this.orderPrice = this.orderInfo.nopay_price
            },100)
        }
        this.changeGoods = this.cartList[this.activeGoods] || {}
        let _this = this
        document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            let keycodeVue = e1 && e1.keyCode
            console.log(keycodeVue);
            if(keycodeVue == 112){       //F1 会员
                e.preventDefault();   
                _this.showMember = true
            }else if(keycodeVue == 113){   //F2 数量
                e.preventDefault();
                _this.changeNumHandle('num')
            }else if(keycodeVue == 114){   //F3 改价
                e.preventDefault();
                _this.changeNumHandle('price')
            }else if(keycodeVue == 115){    //F4 优惠
                e.preventDefault();
                
            }else if(keycodeVue == 116){    //F5 删除
                e.preventDefault();
                _this.deleteGoods()
            }else if(keycodeVue == 117){    //F6 挂单/取单
                e.preventDefault();
                _this.pendingOrderHandle()
            }else if(keycodeVue == 120){ // F9 结账
                e.preventDefault();
                _this.settlement(3)
            }else if(keycodeVue == 121){ // F10 结账
                e.preventDefault();
                _this.settlement(1)
            }else if(keycodeVue == 122){ // F11 结账
                e.preventDefault();
                _this.settlement()
            }
        }  
        const screenObject= JSON.parse(localStorage.getItem('screen'))?JSON.parse(localStorage.getItem('screen')):{use:2,com:undefined,potter:undefined,}
        const com=screenObject.com;
        const potter=parseFloat(screenObject.potter);
        if(this.orderPrice && screenObject.use==1){
            bound.vScreen(com,potter,_this.orderPrice,false)
        }else if(this.total_money && screenObject.use==1){
            bound.vScreen(com,potter,_this.total_money,false)
        }else{
             bound.vScreen(com,potter,"0.00",false)
        }
    },
    methods:{
        ...mapMutations(['set_cartList','set_pendingOrder' , 'set_currentMember' , 'set_weightGoods' , 'set_orderInfo']),
        checkGoods(item){
            if(this.have_payLog){
                this.$message.warning('订单已进入支付流程，请进行结算操作或挂单操作')
            }else if(item.is_soldout===2){
                this.$message.warning('该商品已售罄')
            }else{
                this.have_order = false
                // if(item.is_weighing == 2){
                //     item.total_num = 1
                //     item.goods_original_price = item.goods_price
                //     this.set_weightGoods(item)
                //     this.showWeight = true
                // }else 
                let is_spec_no = this.searchInputValue && (item.spec_content.indexOf(this.searchInputValue) > -1)
                if(is_spec_no){ //是扫的多规格商品条码
                    JSON.parse(item.spec_content).forEach(ele => {
                        if(ele.goods_no == this.searchInputValue){
                            item.guige = ele.spec_name
                            item.goods_price = ele.spec_price
                        }
                    })
                }
                if(item.spec_type == 20 && is_spec_no && !item.avoid && !item.practice && !item.taste){
                    let list = JSON.parse(JSON.stringify(this.cartList)) || []
                    item.total_num = 1
                    item.is_giving=1
                    item.goods_original_price = item.goods_price
                    list.unshift(item)
                    this.set_cartList(list)
                    this.activeGoods = 0
                    this.changeGoods = this.cartList[this.activeGoods]
                    this.searchInputValue = ''
                }else
                if(item.avoid || item.practice || item.taste || item.spec_type == 20 || item.is_weighing == 2){
                    item.total_num = 1
                    item.is_giving=1
                    item.goods_original_price = item.goods_price
                    this.set_weightGoods(item)
                    this.showGuige = true
                    this.searchInputValue = ''
                }else{
                    let list = JSON.parse(JSON.stringify(this.cartList)) || []
                    item.total_num = 1
                    item.is_giving=1
                    item.goods_original_price = item.goods_price
                    list.unshift(item)
                    this.set_cartList(list)
                    this.activeGoods = 0
                    this.changeGoods = this.cartList[this.activeGoods]
                }
            }
        },
        checkCartGoods(i){
            this.activeGoods = i
            this.changeGoods = this.cartList[this.activeGoods]
        },
        openLinshi(val){
            if(this.have_payLog){
                this.$message.warning('订单已进入支付流程，请进行结算操作或挂单操作')
            }else{
                if(val == 1){
                    this.showLinshi = true 
                }else{
                    this.showLinshiCZ = true
                }
            }
            
        },
        changeNum(item,type,i){
            if(this.have_payLog){
                this.$message.warning('订单已进入支付流程，请进行结算操作或挂单操作')
                return
            }
            if(type === 'jia'){
                // if(item.total_num < item.stock_total || item.is_oversold == 2){
                    let list = JSON.parse(JSON.stringify(this.cartList))
                    list[i].total_num = Number(list[i].total_num) + 1
                    let str = String(list[i].total_num)
                    if(str.split('.') && str.split('.')[1] && str.split('.')[1].length > 2){
                        list[i].total_num = list[i].total_num.toFixed(2)
                    }
                    this.set_cartList(list)
                // }else{
                //     this.$message.warning('库存不足')
                // }
            }else{
                if(item.total_num > 1){
                    let list = JSON.parse(JSON.stringify(this.cartList))
                    list[i].total_num = Number(list[i].total_num) - 1
                    let str = String(list[i].total_num)
                    if(str.split('.') && str.split('.')[1] && str.split('.')[1].length > 2){
                        list[i].total_num = list[i].total_num.toFixed(2)
                    }
                    this.set_cartList(list)
                }else{
                    let _this = this
                    this.$confirm({
                        title: "提示",
                        content: "确定要删除该商品吗？",
                        okText: "确定",
                        cancelText: "取消",
                        onOk(){
                            let list = JSON.parse(JSON.stringify(_this.cartList))
                            list.splice(i,1)
                            _this.set_cartList(list)
                            if(_this.activeGoods > 0 && (_this.activeGoods + 1 > _this.cartList.length)){
                                _this.activeGoods -= 1
                            }
                            _this.changeGoods = _this.cartList[_this.activeGoods] || {}
                        }
                    });
                }
                
            }
        },
        changeNumHandle(type){
            if(this.have_payLog){
                this.$message.warning('订单已进入支付流程，请进行结算操作或挂单操作')
            }else{
                this.have_order = false
                let list = JSON.parse(JSON.stringify(this.cartList))
                console.log('list',list);
                if(type === 'num'){
                    if(list && list.length){
                        this.showChangeNum = true
                    }else{
                        this.$message.warning('没有可改数商品')
                    }
                }else if(type === 'price'){
                    if(list && list.length){
                        this.showChangePrice = true
                    }else{
                        this.$message.warning('没有可改价商品')
                    }
                }
            }
            
            
        },
        deleteGoods(){
            if(this.have_payLog){
                this.$message.warning('订单已进入支付流程，请进行结算操作或挂单操作')
            }else{
                this.have_order = false
                let list = JSON.parse(JSON.stringify(this.cartList))
                if(list && list.length){
                    list.splice(this.activeGoods,1)
                    this.set_cartList(list)
                    if(this.activeGoods > 0 && (this.activeGoods + 1 > this.cartList.length)){
                        this.activeGoods -= 1
                    }
                    this.changeGoods = this.cartList[this.activeGoods] || {}
                }else{
                    this.$message.warning('没有可删除商品')
                }
            }
        },
        pendingOrderHandle(){
            if((!this.cartList || !this.cartList.length) && (!this.pendingOrder || !this.pendingOrder.length)){
                this.$message.warning('没有可挂单商品')
                return
            }
            if(this.have_payLog){
                // this.$message.warning('订单已存在支付记录，不能进行该操作')
                if(!this.cartList || !this.cartList.length){
                    this.showPend = true
                }else{
                    let list = JSON.parse(JSON.stringify(this.cartList))
                    let pendingList = JSON.parse(JSON.stringify(this.pendingOrder))
                    if(list && list.length){
                        let time = +new Date()
                        pendingList.unshift({
                            time,list,
                            orderInfo:this.orderInfo
                        })
                        this.set_pendingOrder(pendingList)
                        this.set_cartList([])
                        this.set_orderInfo({})
                        this.set_currentMember({})
                        this.changeGoods = {}
                    }else{
                        
                    }
                    
                }
            }else{
                this.have_order = false
                if(!this.cartList || !this.cartList.length){
                    this.showPend = true
                }else{
                    let list = JSON.parse(JSON.stringify(this.cartList))
                    let pendingList = JSON.parse(JSON.stringify(this.pendingOrder))
                    if(list && list.length){
                        let time = +new Date()
                        pendingList.unshift({
                            time,list,
                        })
                        this.set_pendingOrder(pendingList)
                        this.set_cartList([])
                        this.changeGoods = {}
                    }else{
                        this.$message.warning('没有可挂单商品')
                    }
                    
                }
            }
        },
        empty(){
            if(this.have_payLog){
                this.$message.warning('订单已进入支付流程，请进行结算操作或挂单操作')
            }else{
                this.have_order = false
                if(this.cartList && this.cartList.length){
                    let _this = this
                    this.$confirm({
                        title: "提示",
                        content: "确定要清空吗？",
                        okText: "确定",
                        cancelText: "取消",
                        onOk() {
                            let list = []
                            _this.set_cartList(list)
                            _this.set_orderInfo({})
                        },
                    });
                }else{
                    this.$message.warning('没有可清空商品')
                }
            }
        },
        handleSearch(value) {
            if(!value) return
            if(value.length == 18 || value.length == 19){
                this.pay_code = value
                this.settlement(2)
                this.clearVal += 1
            }else{
                const message = {
                    page:1,
                    keywords:value,
                    page_size:10000,
                }
                this.searchInputValue = value
                goodsList(message).then((res) => {
                    if(res.data.status===200){
                        const list = res.data.data.list && res.data.data.list.data;
                        if(list && list.length == 0){
                            this.barcode=value;
                            this.showScanTip=true; 
                            this.clearVal += 1
                        }else if(list && list.length == 1){
                            this.checkGoods(list[0])
                            this.searchData = []
                            this.clearVal += 1
                        }else{
                            this.searchData = list;
                        }
                    }           
                })
            }
        },
        handleChange(value) {
            let i = this.searchData.findIndex(item => item.goods_id === value.goods_id)
            this.checkGoods(this.searchData[i])
            this.searchData = []
            this.clearVal += 1
        },
        deleteMember(){ //删除会员
            if(this.orderInfo && this.orderInfo.foundingLogId){
                orderUser({
                    keywords:this.currentMember.mobile,
                    founding_log_id:this.orderInfo.foundingLogId,
                    select_type:2
                }).then( res=> {
                    if(res.data.status == 200){
                        this.set_currentMember({})
                    }
                })
            }else{
                this.set_currentMember({})
            }
        },
        settlement(type){ //结算 have_payLog   // type 1 现金 2 扫码  3 打开扫码弹窗
            if(this.cartList && this.cartList.length){
                if(this.have_payLog){
                    if(type == 1 || type == 2){
                        this.xianjinPay(type)
                    }else if(type == 3){
                        this.showSaomaPay = true
                    }else{
                        this.showSettleHandle = true
                    }
                    
                }else{
                    this.showLoding = true
                    Founding().then(res => {
                        if(res.data.status == 200){
                            let order = JSON.parse(JSON.stringify(this.cartList))
                            order.forEach(item => {
                                item.taste = item.kouwei || ''
                                item.practice = item.zuofa || ''
                                item.avoid = item.jikou || ''
                                item.spec_content = item.guige || ''
                                item.is_foodback = '1'
                                item.foodback_desc = ''
                                item.is_delete = '1'
                                item.delete_desc = ''
                            })
                            let data = {
                                founding_log_id:res.data.data.founding_log_id,
                                is_print: 1,
                                order
                            }
                            orderCreate(data).then(res_res => {
                                if(res_res.data.status == 200){
                                    this.set_orderInfo({
                                        foundingLogId : data.founding_log_id,
                                        ...res_res.data.data
                                    })
                                    this.have_order = true
                                    this.showLoding = false
                                    if(this.currentMember && this.currentMember.mobile){
                                        orderUser({
                                            keywords:this.currentMember.mobile,
                                            founding_log_id:res.data.data.founding_log_id,
                                            select_type:1
                                        }).then( resU=> {
                                            if(resU.data.status === 200){
                                                if(type == 1 || type == 2){
                                                    this.xianjinPay(type)
                                                }else if(type == 3){
                                                    this.showSaomaPay = true
                                                }else{
                                                    this.showSettleHandle = true
                                                }
                                            }
                                        })
                                    }else{
                                        if(type == 1 || type == 2){
                                            this.xianjinPay(type)
                                        }else if(type == 3){
                                            this.showSaomaPay = true
                                        }else{
                                            this.showSettleHandle = true
                                        }
                                    }
                                }
                            }).finally(err => {
                                this.showLoding = false
                            })
                            setTimeout(() => {
                                this.showLoding = false
                            },8000)
                        }
                    })
                }
            }else{
                this.$message.warning('没有可结算商品')
            }
        },
        xianjinPay(type){
            OrderDetail({foundingLoId : this.orderInfo.foundingLogId}).then(res_order => {
                if(res_order.data.status === 200){
                    this.set_orderInfo({
                        ...res_order.data.data,
                        foundingLogId:res_order.data.data.founding_log_id
                    })
                    orderpay({
                        foundingLoId:this.orderInfo.foundingLogId,
                        pay_type: type == 1 ? 'cash' : 'aggregatepay',
                        auth_no: type == 1 ? '' : this.pay_code,
                        current_price: this.orderInfo.nopay_price
                    }).then(res => {
                        if(res.data.status === 200){
                            this.getStatus(res.data.data , type)
                        }
                    })
                }
            })
        },
        getStatus(data , type){
            orderpaystatus(data).then(res => {
                if(res.data.status === 200){
                    if(res.data.data.data_state == 2){
                        if(type == 1){
                            this.$message.success('现金支付成功')
                            this.FinishOrder()
                        }else{
                            if(res.data.data.status == 2){
                                this.$message.success('扫码支付成功')
                                this.FinishOrder()
                                if(res.data.data.speech_path){
                                     bound.play(res.data.data.speech_path)
                                }
                            }else{
                                this.$message.error('支付失败请重试')
                            }
                        }
                    }else{
                        setTimeout(() => {
                            this.getStatus(data)
                        },500)
                    }
                }
            })
        },
        FinishOrder(val){ //完成结算
            if(val){
                this[val] = false
            }
            let founding_log_id = this.orderInfo.foundingLogId
            this.goodsRequest(this.category_id)
            this.set_orderInfo({})
            this.set_currentMember({})
            this.set_cartList([])
            this.socketApi.websocketsend({"key": "jiezhangAction",value:{token: Cookies.get("Access-Token"),founding_log_id} }); 
            this.socketApi.websocketsend({"key": "diancaiAction",value:{token: Cookies.get("Access-Token"),founding_log_id} }); 
        },
        tabRequest(){  //商品分类请求
            categoryList().then((res) => {
                if(res.data.status===200){
                    const list =res.data.data.list && res.data.data.list;
                    this.cateList = list;
                    if(this.cateList.length > 5){
                        this.rightBtn = 4
                    }
                }           
            })
        },
        goodsRequest(id){    //商品列表请求
            this.category_id = id
            this.showAllCate = false
            let i = this.cateList.findIndex(item => item.category_id == id)
            let index = i > -1 ? i : 0
            if(index - 2 > -1 && index + 3 < this.cateList.length){
                this.srrollWidth = (index - 2) * 9
            }else if(index + 3 >= this.cateList.length ){
                this.srrollWidth = (this.cateList.length - 6) * 9
            }else{
                this.srrollWidth = 0
            }
            const message = {
                // page:1,
                category_id: id == 'all' ? '' : id,
                // page_size:10000,
                noPage:'yes'
            }   
            goodsList(message).then((res) => {
                if(res.data.status===200){
                    const list = res.data.data && res.data.data.list;
                    list.forEach(item => {
                        if(item.spec_type == 20){
                            let price = 0
                            JSON.parse(item.spec_content).forEach((ele,i) => {
                                if(i == 0){
                                    price = ele.spec_price
                                }else{
                                    price = (price > ele.spec_price ? ele.spec_price : price)
                                }
                            })
                            item.goods_price = Number(price).toFixed(2)
                        }
                    })
                    this.goodsList=list;
                }           
            })
        },
        searchGoodsHandle(){
            const message = {
                page:1,
                goods_name: this.goods_name,
                page_size:10000,
            }   
            goodsList(message).then((res) => {
                if(res.data.status===200){
                    const list = res.data.data.list && res.data.data.list.data;
                    this.searchGoodsList = list;
                    this.showNoGoods = true
                }           
            })
        },
        numChange(item){
            this.$set(item , 'total_num' , item.total_num.replace(/[^0-9]/ig,""))
        },
        scrollHandle(type){
            let box_width = this.cateList.length * 8
            if(type === 'left'){
                this.rightBtn = 5
                if(this.srrollWidth < 40 || this.srrollWidth == 40){
                    this.srrollWidth = 0 
                    this.leftBtn = 0
                }else{
                    this.srrollWidth = this.srrollWidth - 40
                }
            }else{
                this.leftBtn = 4
                if(this.srrollWidth + 80 > box_width || this.srrollWidth + 80 == box_width){
                    this.srrollWidth = box_width - 48
                    this.rightBtn = 0
                }else{
                    this.srrollWidth = this.srrollWidth + 40
                    this.srrollWidth >= box_width - 48 ? this.rightBtn = 0 : ''
                }
            }
        },
        memberOkHandle(type){
            if(type === 'openRecharge'){
                this.showRecharge = true
            }else if(type === 'openSettle'){
                this.isSettle = false
                this.showMember = false
                this.showSettleHandle = true
            }else if(type === 'openHuiyuanPay'){
                this.fromHuiyuanPay = false
                this.showMember = false
                this.showHuiyuanPay = true
            }
        },
        handleOrder(type){
            console.log(type);
            this.showSettleHandle = false
            if(type === 'xianjin'){
                this.showXianjinPay = true
            }else if(type === 'weixin'){
                this.showWeixinPay = true
            }else if(type === 'zhifubao'){
                this.showZhifubaoPay = true
            }else if(type === 'saoma'){
                this.showSaomaPay = true
            }else if(type === 'huiyuan'){
                this.showHuiyuanPay = true
            }else if(type === 'zhekou'){
                this.showZhekou = true
            }else if(type === 'jianmian'){
                this.showJianmian = true
            }else if(type === 'changeMember'){
                this.isSettle = true
                this.showMember = true
            }else if(type === 'checkMember'){
                this.fromHuiyuanPay = true
                this.showMember = true
            }
        },
        goSettlePage(type,vip){
            if(vip == 'chongzhi'){
                this.showRecharge = true
            }else{
                console.log(type);
                // 返回结算页
                if(type === 'xianjin'){
                    this.showXianjinPay = false
                }else if(type === 'weixin'){
                    this.showWeixinPay = false
                }else if(type === 'zhifubao'){
                    this.showZhifubaoPay = false
                }else if(type === 'saoma'){
                    this.showSaomaPay = false
                }else if(type === 'huiyuan'){
                    this.showHuiyuanPay = false
                }else if(type === 'zhekou'){
                    this.showZhekou = false
                }else if(type === 'jianmian'){
                    this.showJianmian = false
                }
                if(vip == 'saoma'){
                    this.showSaomaPay = true
                }else if(vip == 'xianjin'){
                    this.showXianjinPay = true
                }else{
                    this.showSettleHandle = true
                }
                
            } 
        },
        handleSearchs(value) {
            console.log(value)
            const message = {
                page:1,
                keywords: this.barcode,
                page_size:10000,
            }
            goodsList(message).then((res) => {
                if(res.data.status===200){
                    const list = res.data.data.list && res.data.data.list.data;
                    if(list && list.length == 0){
                        this.barcode=value;
                        this.showScanTip=true; 
                        this.clearVal += 1
                    }else if(list && list.length == 1){
                        this.checkGoods(list[0])
                        this.searchData = []
                        this.clearVal += 1
                    }else{
                        this.searchData = list;
                    }
                }           
            })
        },
        getDetail(){
            getScreenSetting({key:'goods_model'}).then(res => {
                if(res.data.status === 200){
                    const values=res.data.data.values.model_type;
                    this.model_type=values || 1
                    this.rowNum=res.data.data.values.column_num || 6;

                }
            })
        },
        async getUndoneOrder(){
            if(!this.cartList || !this.cartList.length){
                let list = []
                await billList({pay_status: 10}).then(res => {
                    if(res.data.status == 200){
                        let data = res.data.data.list.data && res.data.data.list.data
                        data.forEach(item => {
                            let i = this.pendingOrder.findIndex(ele => ele.orderInfo && ele.orderInfo.order_id == item.order_id)
                            if(i < 0){
                                list.push(item)
                            }
                        })
                    }
                })
                if(list && list.length){
                    OrderDetail({foundingLoId : list[0].founding_log_id}).then(res => {
                        if(res.data.status == 200){
                            let data = res.data.data && res.data.data
                            console.log(data);
                            data.foundingLogId = data.founding_log_id
                            this.set_orderInfo(data)
                            this.set_cartList(data.orderGoods)
                            if(data.orderUser){
                                this.set_currentMember(data.orderUser)
                            }
                            setTimeout(() => {
                                this.orderPrice = data.nopay_price
                            },100)
                        }
                    })
                }
            }
        },
         givingButton(){
            let list = JSON.parse(JSON.stringify(this.cartList)) || []
            list[this.activeGoods].is_giving=2;  //2为增菜
            this.set_cartList(list)
        },
        deleGivingButton(){
            let list = JSON.parse(JSON.stringify(this.cartList)) || []
            list[this.activeGoods].is_giving=1;  //2为增菜
            this.set_cartList(list)
        }
        
    },
    beforeDestroy() {
        document.onkeydown = ''
    },

}
</script>
<style lang="less" scoped>
.search_item{
    height:5vh;
    line-height:5vh;
    font-size:2vh;
}
.quickCashier{
    padding-top: 1vh;
    height: 100%;
    font-size: 1.8vh;
    .top{
        display: flex;
        height: 83vh;
        .left{
            width: 25.5vw;
            border-right: 1px solid #e5e5e5;
            position: relative;
            .shangyidan{
                padding: 1vh 1vw 0;
            }
            .money{
                padding: 0 1vw;
                display: flex;
                // line-height: 3.6vh;
                margin: 0.8vh 0;
                .money_left{
                    flex: 3;
                    border-right: 1px solid #e5e5e5;
                    margin-right: 1vw;
                    b{
                        font-size: 2vh;
                    }
                    >div:first-child{
                        margin-bottom: 0.6vh;
                    }
                }
                .money_right{
                    flex: 2;
                    b{
                        font-size: 2.2vh;
                    }
                }
            }
            .inputBox{
                padding: 1vh 1vw;
            }
            .member{
                margin: 0 1vw;
                display: flex;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                padding: 1vh 1vh 1vh 1vw;
                border-radius: 0.5vh;
                img{
                    width: 5vh;
                    height: 5vh;
                    border-radius: 5vh;
                    margin-right: 1vw;
                }
                .info{
                    flex: 1;
                    color: #a6a6a6;
                    font-size: 1.6vh;
                    .photo{
                        font-size: 2vh;
                        font-weight: 700;
                        color: #505050;
                    }
                }
                .close{
                    margin-top: -1vh;
                    font-size: 2vh;
                    i{
                        cursor: pointer;
                    }
                    
                }
            }
            .cartList{
                margin-top: 1vh;
                // height: 63.5vh;
                overflow: auto;
                
                &::-webkit-scrollbar {
                    width : 0px; 
                    height: 0px;
                }
                .goods_item{
                    padding: 1vh 1vw 0;
                    
                    .name,.zuofa{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .zuofa{
                        color: #a6a6a6;
                        font-size: 1.5vh;
                        height: 1.8vh;
                        line-height: 1.8vh;
                    }
                    .price_num{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #e5e5e5;
                        .price{
                            color: #ff3b30;
                        }
                        .num{
                            margin-right: 1vw;
                            display: flex;
                            align-items: center;
                            font-size: 2vh;
                            .icon-jianqu{
                                color: #e5e5e5;
                                font-size: 2.6vh;
                                cursor: pointer;
                            }
                            .icon-jia{
                                color: #ff6565;
                                font-size: 2.6vh;
                                cursor: pointer;
                            }
                            .inp{
                                width: 2.5vw;
                                background:none;  
                                outline:none;  
                                border:none;
                                text-align: center;
                                margin: 0 0.5vw;
                                margin-top: -0.3vh;
                                
                            }
                            .inp:focus{   
                                border:none;
                            }
                        }
                    }
                }
            }
            .btn_box{
                display: flex;
                justify-content: space-between;
                .jiezhangLoding , .jiezhangBtn{
                    width: 12vw;
                }
                .huiyuan_a{
                   width: 7vw; 
                   font-weight: normal;
                   font-size: 2vh;
                }
                .huiyuan{
                    height: 6.5vh;
                    line-height: 6.5vh;
                    text-align: center;
                    border-radius: 0.5vh;
                    background: #ecedf3;
                    margin: 2vh 1.7vw;
                    cursor: pointer;
                    width: 7vw;
                }
            }
        }
        .right{
            width: 74.5vw;
            background: #f5f5f5;
            .classify{
                display: flex;
                padding: 1.5vh 1vw;
                width: 74.5vw;
                position: relative;
                .all{
                    width: 8vw;
                    min-width: 8vw;
                    height: 5vh;
                    line-height: 5vh;
                    background: #ffffff;
                    margin-right: 1vw;
                    text-align: center; 
                    border-radius: 0.5vh;
                    cursor: pointer;
                }
                .xiang{
                    width: 0vw;
                    height: 5vh;
                    line-height: 5vh;
                    background: #ffffff;
                    margin-left: 1vw;
                    text-align: center; 
                    border-radius: 0.5vh;
                    transition: all 1s;
                    overflow: hidden;
                    cursor: pointer;
                }
                .scroll_box{
                    flex: 1;
                    overflow: hidden;
                    .scroll_box2{
                        display: flex;
                        transition: all 1s;
                    }
                }
                .allBox{
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    width: 100%;
                    padding: 1.5vh 1vw;
                    flex-wrap: wrap;
                    z-index: 99;
                    margin-right: 1vw;
                    background: #f5f5f5;
                    >div{
                        margin-bottom: 1vh;
                    }
                }
            }
            .goods_list{
                padding:0 0 1.5vh 1vw;
                width: 74.5vw;
                height: 74vh;
                overflow: hidden;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width : 0px;
                    height: 3px;
                }
                .goods_item{
                    >div:first-child{
                        width: 100%;
                        height: 100%;
                    }
                    float: left;
                    // display: inline-block;
                    background: #ffffff;
                    // width: 11.2vw;
                    // max-height: 15vw;
                    // height:20vh;
                    margin-right: 1vw;
                    margin-bottom: 1vh;
                    padding: 0.6vh 0.5vw;
                    border-radius: 0.5vh;
                    text-align: center;
                    cursor: pointer;
                    // font-size: 2vh;
                    position: relative;
                    .bigFont{
                        // height: 13vh;
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .names{
                            // font-size: 2.3vh;
                            // font-weight: bold;
                            // width: 9.6vw;
                            // width: 100%;
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-wrap: break-word;
                            white-space: normal !important;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            font-family: 'PF' !important;
                            color:#666666;
                        }
                        .price{
                            // font-size: 2.2vh;
                            font-weight: bold;
                            margin-top:1vh;
                        }
                    }
                    .weighing{
                        position: absolute;
                        top: 0.6vh;
                        right: 0.5vw;
                        background: #ffc300;
                        border-radius: 0.5vh;
                        font-size: 1.5vh;
                        padding: 0.1vh 0.3vw;
                    }
                    .shouqing{
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: rgba(0,0,0,0.3);
                        border-radius: 0.5vh;
                        .bg{
                            position: absolute;
                            border: 24px solid #ff5050;
                            border-left: 24px solid rgba(0,0,0,0);
                            border-bottom: 24px solid rgba(0,0,0,0);
                            border-top-right-radius: 0.5vh;
                            top: 0;
                            right: 0;
                        }
                        .text{
                            position: absolute;
                            top: 5px;
                            right: 2px;
                            color: #ffffff;
                            transform: rotate(45deg);
                            font-size: 13px;
                        }
                    }
                    .img_box{
                        width: 100%;
                        height: 68%;
                        img{
                            // width: 9.6vw;
                            // height: 9.6vw;
                            width: 100%;
                            height: 100%;
                            border-radius: 0.5vh;
                        }
                    }
                    // &:nth-child(5n){
                    //     margin-right: 0;
                    // }
                    .name{
                        // font-size: 2vh;
                        // width: 9.6vw;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .price{
                        color: #d95e58;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .bottom{
        height: 10.8vh;
        background: #3b3b45;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1vw;
        .left_btn{
            display: flex;
            >div{
                height: 5.2vh;
                line-height: 5.2vh;
                text-align: center;
                border-radius: 0.5vh;
                background: #ecedf3;
                margin-right: 1vw;
                width: 7.2vw;
                cursor: pointer;
            }
        }
        .right_btn{
            display: flex;
            >div{
                font-weight: normal;
                font-size: 2vh;
                width: 8.5vw;
                line-height: 2.5vh;
                padding: 0.8vh 0;
                margin: 1vh 1vw;
                &:first-child{
                    background: #00baad;
                }
                &:last-child{
                    width: 12vw;
                }
            }
        }
    }
    .searchGoods{
        position: fixed;
        background: rgba(0,0,0,0.5);
        top: 13vh;
        right: 2vw;
        padding: 1vh 0.6vw;
        width: 25vw;
        border-radius: 1vh;
        .searchInp{
            background: #ffffff;
            border-radius: 0.6vh;
            display: flex;
            align-items: center;
            height: 6vh;
            >span{
                width: 10vw;
                height: 2vh;
                line-height: 2vh;
                border-left: 1px solid #d95e58;
                text-align: center;
                color: #d95e58;
                font-weight: 700;
                cursor: pointer;
            }
            .inp , .inp:focus , .inp:active{
                height: 4vh;
                font-size: 2vh;
                color: #ff6565;
                border: none;
                outline:none;
                resize:none;
                margin-left: 1vw;
            }
        }
        .searchGoodsList{
            background: #ffffff;
            border-radius: 0.6vh;
            margin-top: 1vh;
            font-size: 2vh;
            padding: 1vh 0;
            max-height: 76vh;
            overflow-y: scroll;
            .item{
                height: 6vh;
                line-height: 6vh;
                padding: 0 1vw;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &::-webkit-scrollbar {
                width : 5px; 
                height: 5px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background   : #ff6565;
            }
            // &::-webkit-scrollbar-track {
            //     border-radius: 6px;
            //     background   : #ffebe8;
            // }
        }
        .noGoods{
            height: 6vh;
            line-height: 6vh;
            padding: 0 1vw;
            color: #a6a6a6;
        }
    }
}

</style>